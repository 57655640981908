@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #09090b;
}

/* @media screen and (max-width: 500px) {
body {
 margin:8px
}
} */

.mapboxgl-control-container,
.mapboxgl-children,
mapboxgl-children {
  display: none;
}

.mapboxgl-popup-content {
  padding: 14px 12px !important;
  font-family: "IBM Plex Sans", serif;
  /* background: #09090b !important; */
}

/* .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #09090b !important;
} */

.mapboxgl-popup-close-button {
  right: 3px;
}
